import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-catalog-settings',
  templateUrl: './catalog-settings.component.html',
  styleUrls: ['./catalog-settings.component.scss'],
})
export class CatalogSettingsComponent implements OnInit {

  constructor(
    private popoverCtr: PopoverController
  ) { }

  ngOnInit() {}

  toggleArchived() {
    this.popoverCtr.dismiss({
      'toggleArchived': true
    })
  }

}
