export const environment = {
  production: true,
  url : "https://us-central1-ingka-us-food-prod.cloudfunctions.net/searchMenuItems",

  firebase: {
    apiKey: "AIzaSyBI3fNDOAAWd1a_Px9OUS3tvMBDJgpVqbk",
    authDomain: "ingka-us-food-prod.firebaseapp.com",
    databaseURL: "https://ingka-us-food-prod.firebaseio.com",
    projectId: "ingka-us-food-prod",
    storageBucket: "ingka-us-food-prod.appspot.com",
    messagingSenderId: "396513188434",
    appId: "1:396513188434:web:71ef59a231441dc6069d8b"
  },
  stripe: 'pk_live_51JxuaIJNtxPAq1nTwPMNxpygUCaNK2cUnfQZol16htAAgxLR4QrnJYGd4c1f3XzfXEBsTwgOitXGIWNNd8iz7IFD00wRkOj4UL',
  auth0: {
    clientID: 'llx9XjSACRc156ShTHyuiBLAscWIuMDV',
    domain: 'us.accounts.ikea.com',
    audience: 'https://us.accounts.ikea.com/api/v2/',
    scope: 'openid profile email'
  }
};
