import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
  showHours: boolean = false
  @Input() storeInfo = {
    name: '',
    address: '',
    mobileOrdering: false,
    location_hours: {
      sunday: {
        closeTime: '',
        openTime: ''
      },
      monday: {
        closeTime: '',
        openTime: ''
      },
      tuesday: {
        closeTime: '',
        openTime: ''
      },
      wednesday: {
        closeTime: '',
        openTime: ''
      },
      thursday: {
        closeTime: '',
        openTime: ''
      },
      friday: {
        closeTime: '',
        openTime: ''
      },
      saturday: {
        closeTime: '',
        openTime: ''
      }
    }
  } 
  constructor(
    private modalCtrl: ModalController
  ) { }
  
  ngOnInit() {
  }

  dismiss() {
    this.modalCtrl.dismiss()
  }

  toggleHours() {
    this.showHours = !this.showHours
  }

}
