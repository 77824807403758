import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import * as auth0 from 'auth0-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerAuthService {

  auth0 = new auth0.WebAuth({
    clientID: environment.auth0.clientID,
    domain: environment.auth0.domain,
    responseType: 'token id_token',
    redirectUri: window.location.origin + '/callback',
    scope: environment.auth0.scope
  });

  constructor(
    private functions: AngularFireFunctions,
    private loadingController: LoadingController,
    private router: Router,
    private afAuth: AngularFireAuth,
    private alertController: AlertController
  ) { }


  async auth0login() {
    const loading = await this.loadingController.create({
      message: '',
      animated: true,
    });
    loading.present()
    this.auth0.authorize();
  }

  async auth0logout() {
    this.auth0.logout({
      returnTo: window.location.host + '/',
      clientID: environment.auth0.clientID
    });
  }

  async handleLoginCallback() {
    console.log('handle login callback')
    // When Auth0 hash parsed, get profile
    this.auth0.parseHash(async (err, authResult) => {
      if (authResult !== null) {

        if (authResult && authResult.accessToken) {
          window.location.hash = '';
          // Store access token
          const callable = this.functions.httpsCallable('issueToken');
          const loading = await this.loadingController.create({
            message: 'Loading Account'
          });
          loading.present()
          const obs = callable({
            jwt: authResult.idToken,
            idTokenPayload: authResult.idTokenPayload
          });

          obs.subscribe(async (res) => {
            await this.afAuth.auth.signInWithCustomToken(res.firebaseToken);
            loading.dismiss()
            this.router.navigateByUrl('/my-account')
          }, async (err) => {
            loading.dismiss()
            const alert = await this.alertController.create({
              header: 'Authentication Error',
              message: err.message,
              buttons: ['Okay']
            });
            await alert.present();
          });
        }
        // Get user info: set up session, get Firebase token
        else (err => {
          console.error(`Error authenticating: ${err.error}`);
        })
      } else {
        this.auth0login()
      }
    });
  }


}
