import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-create-fulfillment-method',
  templateUrl: './create-fulfillment-method.component.html',
  styleUrls: ['./create-fulfillment-method.component.scss'],
})
export class CreateFulfillmentMethodComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss()
  }

}
