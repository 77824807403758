import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from './guards/auth.guard';
import { CustomerAuthGuard } from './guards/customerGuard.guard';

const routes: Routes = [
  {
    path: "admin",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./food/home/home.module").then(
        m => m.HomePageModule
      )
  },
  {
    path: "admin-login",
    loadChildren: () =>
      import("./auth/auth.module").then(
        m => m.LoginPageModule
      )
  },
  {
    path: 'user-administration',
    loadChildren: () => import('./user/list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./food/menu/list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'add-item',
    loadChildren: () => import('./food/menu/add-item/add-item.module').then(m => m.AddItemPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./kiosk/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./kiosk/menu/menu.module').then(m => m.MenuPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./foodfacts/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'type/:storeFormat',
    loadChildren: () => import('./foodfacts/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'kiosk/:storeId/:storeFormat/:kiosk',
    loadChildren: () => import('./foodfacts/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'store/:storeId/:storeFormat',
    loadChildren: () => import('./foodfacts/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'nutrition-menu/:storeId',
    loadChildren: () => import('./foodfacts/list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'nutrition-menu/:storeId/restaurant',
    loadChildren: () => import('./pages/restaurant/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'nutrition-menu/:storeId/bistro',
    loadChildren: () => import('./pages/bistro/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'nutrition-menu/:storeId/sfm',
    loadChildren: () => import('./pages/sfm/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'choose-format/:storeid',
    loadChildren: () => import('./foodfacts/choose/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'nutrition-details/:menuItemId',
    loadChildren: () => import('./foodfacts/details/details.module').then(m => m.DetailsPageModule)
  },
  {
    path: 'nutrition-details/:menuItemId/:storeId',
    loadChildren: () => import('./foodfacts/details/details.module').then(m => m.DetailsPageModule)
  },
  {
    path: 'nutrition-details/:menuItemId/:storeId/:storeFormat',
    loadChildren: () => import('./foodfacts/details/details.module').then(m => m.DetailsPageModule)
  },
  {
    path: 'celebration-details/:celebrationId',
    loadChildren: () => import('./foodfacts/celebration-details/celebration-details.module').then(m => m.CelebrationDetailsPageModule)
  },
  {
    path: 'ordering/foodservice/details/:menuItemId',
    loadChildren: () => import('./ordering/details/details.module').then(m => m.DetailsPageModule)
  },
  {
    path: 'ordering-details/:menuItemId/:storeId',
    loadChildren: () => import('./ordering/details/details.module').then(m => m.DetailsPageModule)
  },
  {
    path: 'ordering-details/:menuItemId/:storeId/:storeFormat',
    loadChildren: () => import('./ordering/details/details.module').then(m => m.DetailsPageModule)
  },
  {
    path: 'ordering/list/:storeId',
    loadChildren: () => import('./ordering/list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'ordering/list/:storeId/:storeFormat',
    loadChildren: () => import('./ordering/list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'cart',
    canActivate: [CustomerAuthGuard],
    loadChildren: () => import('./ordering/cart/cart.module').then(m => m.CartPageModule)
  },
  {
    path: 'status',
    loadChildren: () => import('./ordering/status/status.module').then(m => m.StatusPageModule)
  },
  {
    path: 'status/:orderId',
    loadChildren: () => import('./ordering/status/status.module').then(m => m.StatusPageModule)
  },
  {
    path: 'curbside',
    loadChildren: () => import('./ordering/pickup/curbside/curbside.module').then(m => m.CurbsidePageModule)
  },
  {
    path: 'curbside/:orderId',
    loadChildren: () => import('./ordering/pickup/curbside/curbside.module').then(m => m.CurbsidePageModule)
  },
  {
    path: 'ordering/sfm/list/:storeId',
    loadChildren: () => import('./ordering/sfm/list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'ordering',
    canActivate: [AuthGuard],
    loadChildren: () => import('./ordering/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'ordering/sfm/details/:menuItemId',
    loadChildren: () => import('./ordering/sfm/details/details.module').then(m => m.DetailsPageModule)
  },
  {
    path: 'wait',
    loadChildren: () => import('./ordering/wait/wait.module').then(m => m.WaitPageModule)
  },
  {
    path: 'wait/:orderId',
    loadChildren: () => import('./ordering/wait/wait.module').then(m => m.WaitPageModule)
  },
  {
    path: 'signature',
    loadChildren: () => import('./ordering/signature/signature.module').then(m => m.SignaturePageModule)
  },
  {
    path: 'finished',
    loadChildren: () => import('./ordering/finished/finished.module').then(m => m.FinishedPageModule)
  },
  {
    path: 'kds',
    canActivate: [AuthGuard],
    loadChildren: () => import('./food/kds/kds.module').then(m => m.KdsPageModule)
  },
  {
    path: 'expo',
    loadChildren: () => import('./food/expo/expo.module').then(m => m.ExpoPageModule)
  },
  {
    path: 'order-verification',
    loadChildren: () => import('./food/order-verification/order-verification.module').then(m => m.OrderVerificationPageModule)
  },
  {
    path: 'rdt-login',
    loadChildren: () => import('./pages/rdt/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'swedish-food-market',
    loadChildren: () => import('./pages/sfm/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'restaurant',
    loadChildren: () => import('./pages/restaurant/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'bistro',
    loadChildren: () => import('./pages/bistro/landing/landing.module').then(m => m.LandingPageModule)
  },

  {
    path: 'restaurant/:area',
    loadChildren: () => import('./pages/product-list/product-list.module').then(m => m.ProductListPageModule)
  },

  {
    path: 'bistro/:area',
    loadChildren: () => import('./pages/product-list/product-list.module').then(m => m.ProductListPageModule)
  },
  {
    path: 'swedish-food-market/:area',
    loadChildren: () => import('./pages/product-list/product-list.module').then(m => m.ProductListPageModule)
  },
  {
    path: 'handout',
    loadChildren: () => import('./food/handout/handout.module').then(m => m.HandoutPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/customer/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'callback',
    loadChildren: () => import('./pages/customer/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'my-account',
    canActivate: [CustomerAuthGuard],
    loadChildren: () => import('./pages/customer/my-account/my-account.module').then(m => m.MyAccountPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/customer/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'detail/:id',
    loadChildren: () => import('./pages/product/product.module').then(m => m.ProductPageModule)
  },
  {
    path: 'food-detail/:id',
    loadChildren: () => import('./pages/product/foodservice-product/foodservice-product.module').then(m => m.FoodserviceProductPageModule)
  },
  {
    path: 'order-status',
    loadChildren: () => import('./pages/order-status/order-status.module').then(m => m.OrderStatusPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutPageModule)
  },
  {
    path: 'label',
    loadChildren: () => import('./pages/label/label.module').then(m => m.LabelPageModule)
  },
  {
    path: 'label/:date/:storeId/:orderId/:labelId/:authCheck',
    loadChildren: () => import('./pages/label/label.module').then(m => m.LabelPageModule)
  },
  { path: '**', redirectTo: '/not-found' },
  {
    path: 'not-found',
    loadChildren: () => import('./errors/error404/error404.module').then(m => m.Error404PageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./pages/rdt/picking/list/list.module').then(m => m.ListPageModule)
  },



























];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
