import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { QuillModule } from 'ngx-quill'
import { QRCodeModule } from 'angularx-qrcode';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { Firebase } from '@ionic-native/firebase/ngx';
import { HttpModule } from '@angular/http';
import { AppVersion } from '@ionic-native/app-version/ngx';
import {AngularFireStorageModule } from '@angular/fire/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { SharedModule } from 'src/app/services/share.module';
import { environment } from '../environments/environment';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { IonicSelectableModule } from 'ionic-selectable';
import { NgxStripeModule } from 'ngx-stripe';
import { SelectModifiersComponent } from './pages/product/select-modifiers/select-modifiers.component';
import { MobileComponent } from './components/navigation/customer/mobile/mobile.component';
import { LocationComponent } from './components/location/location.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AllShareModule } from './services/allShare.module';
import { InfoComponent } from './components/location/info/info.component';
import { CreateFulfillmentMethodComponent } from './food/store/create-fulfillment-method/create-fulfillment-method.component';
import { CatalogSettingsComponent } from './food/menu/list/components/catalog-settings/catalog-settings.component';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [AppComponent, DropZoneDirective, SelectModifiersComponent, MobileComponent, LocationComponent, CreateFulfillmentMethodComponent, InfoComponent, CatalogSettingsComponent],
  entryComponents: [SelectModifiersComponent, MobileComponent, LocationComponent, CreateFulfillmentMethodComponent, InfoComponent, CatalogSettingsComponent],
  imports: [BrowserModule, 
    IonicModule.forRoot(), 
    IonicStorageModule.forRoot(),
    AppRoutingModule, 
    IonicSelectableModule,
    AllShareModule,
    HttpClientModule,
    AngularFireAuthModule,
    HttpModule,
    AngularFireModule.initializeApp(environment.firebase), 
    AngularFirestoreModule,
    SharedModule,
    AngularFireStorageModule,
    NgxStripeModule.forRoot(),
    QuillModule.forRoot(),
    QRCodeModule,
    AngularFireFunctionsModule,
    AngularFireMessagingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
  providers: [
    Firebase,
    StatusBar,
    AppVersion,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: FirestoreSettingsToken, useValue: {} }
  ],
  bootstrap: [AppComponent],
  exports: [NgxStripeModule, LoadingComponent]
})
export class AppModule {}
