import 'rxjs/add/observable/fromPromise';

import { Injectable, NgZone } from '@angular/core';
import { auth } from 'firebase/app';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { switchMap, first, take, map } from 'rxjs/operators';
import { DbService } from './db.service';

import { Platform, LoadingController, ToastController, AlertController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { Http } from '@angular/http';

import { GraphService } from 'src/app/services/graph.service';
import { duration } from 'moment';
import { SelectModifiersComponent } from '../pages/product/select-modifiers/select-modifiers.component';
import { CustomerAuthService } from './customer-auth.service';


@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any;
  user$: Observable<any>;
  token: any;
  loggedInUid: string = ''
  constructor(
    public afStore: AngularFirestore,
    private afAuth: AngularFireAuth,
    private db: DbService,
    private modalController: ModalController,
    private platform: Platform,
    private router: Router,
    private storage: Storage,
    private toastCtrl: ToastController,
    private loadingController: LoadingController,
    private customerAuth: CustomerAuthService,
    public alertController: AlertController,
    public ngZone: NgZone,
    private http: Http,
    private graphService: GraphService
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => (user ? db.doc$('users/' + user.uid) : of(null)))
    );

    // Will need to check for platform in future, and only initiate 
    // handleRedirect() when platform.is('pwa'||'desktop')
    if (1 == 1) {
      this.handleRedirect();
    }

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        this.loggedInUid = user.uid
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })
  }

  // Get UID
  uid() {
    return this.user$
      .pipe(
        take(1),
        map(u => u && u.uid)
      )
      .toPromise();
  }

  // Get Comment User Information
  getCommentUser() {
    let user = JSON.parse(localStorage.getItem('user'))
    return user.uid;
  }

  // Get User Information
  getUser() {
    return this.user$.pipe(first()).toPromise();
  }

  // Web Redirect Helpers
  setRedirect(val) {
    this.storage.set('authRedirect', val);
  }

  async isRedirect() {
    return await this.storage.get('authRedirect');
  }

  // Handle Web Redirect
  private async handleRedirect() {
    if ((await this.isRedirect()) !== true) {
      return null;
    }
    const loading = await this.loadingController.create();
    await loading.present();
    const result = await this.afAuth.auth.getRedirectResult();
    await loading.dismiss();
    await this.setRedirect(false);
    return result;
  }

  // Azure AD Authentication - Only for PWA
  async microsoftAuth(): Promise<any> {
    try {
      let user;
      await this.setRedirect(true);
      this.toastCtrl.create({ message: 'Opening Microsoft authentication prompt...', duration: 3000 }).then(r => r.present())
      const provider = new auth.OAuthProvider('microsoft.com');
      provider.setCustomParameters({
        tenant: '720b637a-655a-40cf-816a-f22f40755c2c'
      })
      provider.addScope('mail.send');
      provider.addScope('user.read');
      user = await this.afAuth.auth.signInWithPopup(provider);
      this.storage.set('graphAuthToken', user.credential.accessToken)
      this.token = user.credential.accessToken
      this.userData = {
        uid: user.user.uid,
        email: user.additionalUserInfo.profile.mail,
        givenName: user.additionalUserInfo.profile.givenName,
        displayName: user.additionalUserInfo.profile.displayName,
        location: user.additionalUserInfo.profile.officeLocation,
        role: user.additionalUserInfo.profile.jobTitle,
        permissions: {
          admin_viewer: false,
        },
        country: user.additionalUserInfo.profile.country || 'us',
        language: user.additionalUserInfo.profile.preferredLanguage || 'en',
        firstSignon: user.user.metadata.creationTime,
        lastSignon: new Date().toUTCString(),
      }
      await this.updateUserData(this.userData);
      await this.toastCtrl.create({ message: 'Successfully logged in as ' + this.userData.displayName + '!', duration: 4000 }).then(r => r.present())
    } catch (err) {
      let regex = /FirebaseError*/;
      let checkFirebase = regex.test(err)
      console.log(err)
      if (checkFirebase == false) {
        this.toastCtrl.create({ message: err, duration: 6000 }).then(r => r.present())
      }
    }

  }

  // Update User Data
  private updateUserData({ uid, email, displayName, givenName, role, location, country, language, firstSignon, lastSignon, permissions }) {
    const path = 'users/' + uid;
    const data = {
      uid,
      email,
      displayName,
      givenName,
      role,
      permissions,
      location,
      country,
      language,
      firstSignon,
      lastSignon
    };
    return this.db.updateAt(path, data)
  }

  // Sign-out 
  async signOut() {
    const userInfo = await this.getUser();

    if (((userInfo.customer==true))) {
      await this.afAuth.auth.signOut();
      // this.customerAuth.auth0logout()
    } else {
      await this.afAuth.auth.signOut();
      return this.router.navigate(['/admin-login']);
    }
  }

  public async addToCart(productId: string, type: string, productName: string, price: number, productImage: string, modifiers: any, skipModifierCheck?: boolean) {
    if (modifiers === undefined) {
      modifiers = null
    }
    let modifierCheck: boolean = skipModifierCheck || false
    if ((((modifiers !== null))) && (modifierCheck != true)) {
      // Default modifiers exist for product - prompt user to make modifications before adding item to cart
      console.log('have user select modifiers')
      const modal = await this.modalController.create({
        component: SelectModifiersComponent,
        backdropDismiss: false,
        cssClass: 'medium-modal',
        componentProps: {
          productId: productId,
          type: type,
          productName: productName,
          price: price,
          productImage: productImage,
          modifiers: modifiers
        }
      });
      return await modal.present();
    } else {
      const loading = await this.loadingController.create({ backdropDismiss: false })
      loading.present()
      // Check if a uid is stored (otherwise alert customer to login first)
      if (!this.loggedInUid) {
        loading.dismiss()
        const alert = await this.alertController.create({
          header: 'Login Required!',
          message: 'We can only add items to your cart if you\'re authenticated.',
          backdropDismiss: false,
          buttons: [
            {
              text: 'Login',
              handler: () => {
                this.microsoftAuth().then(() => {
                  // Restart function so item can be added to cart
                  this.addToCart(productId, type, productName, price, productImage, modifiers)

                })
              }
            }
          ]
        });
        await alert.present();
      } else {
        // Get current cart
        const cartPath = this.afStore.collection('users').doc(this.loggedInUid).collection('cart').doc('data')
        await cartPath.get().pipe(
          map(doc => doc.data())
        ).subscribe((res: any) => {
          let currentCart = res
          if (currentCart === undefined) {
            // Cart doesn't exist
            let cartDoc = {
              sfm: [],
              bistro: [],
              restaurant: []
            }
            cartDoc[type].push({
              name: productName,
              image: productImage,
              price: price,
              productId: productId,
              modifiers: modifiers,
              amount: 1
            })
            cartPath.set(cartDoc)
            loading.dismiss()
          } else {
            // Cart does exist
            // Check if item (with modifiers) currently exists
            let currentProductIds = []
            currentCart[type].forEach((item: any) => {
              currentProductIds.push(item.productId)
            })
            if (currentProductIds.includes(productId)) {
              // Cart includes item
              // Check if modifiers match
              let skipAddingSeperateItem = false
              let currentItemNumber = 0
              currentCart[type].forEach((currentItem) => {
                if (JSON.stringify(currentItem.modifiers) === JSON.stringify(modifiers)) {
                  skipAddingSeperateItem = true
                  // Should just increment amount for current item
                  currentCart[type][currentItemNumber].amount += 1
                  cartPath.update(currentCart)
                  loading.dismiss()
                  this.toastCtrl.create({
                    animated: true,
                    message: productName + ' quantity updated to ' + currentCart[type][currentItemNumber].amount + '.',
                    duration: 4000
                  }).then(r => r.present())
                }
                currentItemNumber += 1
              })
              // An exact match was NOT found (probably modifiers different) 
              // Add as a unique item in cart
              if (skipAddingSeperateItem === false) {
                currentCart[type].push({
                  name: productName,
                  image: productImage,
                  price: price,
                  productId: productId,
                  modifiers: modifiers,
                  amount: 1
                })
                cartPath.update(currentCart)
                loading.dismiss()
                this.toastCtrl.create({
                  animated: true,
                  message: productName + ' successfully added to your cart.',
                  duration: 4000
                }).then(r => r.present())
              }
            } else {
              currentCart[type].push({
                name: productName,
                image: productImage,
                price: price,
                productId: productId,
                modifiers: modifiers,
                amount: 1
              })
              cartPath.update(currentCart)
              loading.dismiss()
              this.toastCtrl.create({
                animated: true,
                message: productName + ' successfully added to your cart.',
                duration: 4000
              }).then(r => r.present())
            }
          }
        })
      }
    }
  }

  public async modifyCartItemAmount(productId: string, type: string, amount: number, modifiers: any) {
    if (amount !== 0) {
      const loading = await this.loadingController.create({ backdropDismiss: false })
      loading.present()
      // Get current cart
      const cartPath = this.afStore.collection('users').doc(this.loggedInUid).collection('cart').doc('data')
      await cartPath.get().pipe(
        map(doc => doc.data())
      ).subscribe((res: any) => {
        let currentCart = res

        // Cart does exist
        // Check if item (with modifiers) currently exists
        let currentProductIds = []
        currentCart[type].forEach((item: any) => {
          currentProductIds.push(item.productId)
        })
        if (currentProductIds.includes(productId)) {
          // Cart includes item
          // Check if modifiers match
          let currentItemNumber = 0
          currentCart[type].forEach((currentItem) => {
            if ((JSON.stringify(currentItem.modifiers) === JSON.stringify(modifiers)) && currentItem.productId === productId) {
              // Should just increment amount for current item
              currentCart[type][currentItemNumber].amount = amount
              cartPath.update(currentCart)
              loading.dismiss()
              this.toastCtrl.create({
                animated: true,
                message: currentItem.name + ' quantity updated to ' + currentCart[type][currentItemNumber].amount + '.',
                duration: 4000
              }).then(r => r.present())
            }
            currentItemNumber += 1
          })

        }


      })
    } else {
      console.log('Delete instead')
      this.removeCartItemAmount(productId, type, modifiers)
    }
  }

  public async removeCartItemAmount(productId: string, type: string, modifiers: any) {
    const loading = await this.loadingController.create({ backdropDismiss: false })
    loading.present()

    // Get current cart
    const cartPath = this.afStore.collection('users').doc(this.loggedInUid).collection('cart').doc('data')
    await cartPath.get().pipe(
      map(doc => doc.data())
    ).subscribe((res: any) => {
      let currentCart = res

      // Cart does exist
      // Check if item (with modifiers) currently exists
      let currentProductIds = []
      currentCart[type].forEach((item: any) => {
        currentProductIds.push(item.productId)
      })
      if (currentProductIds.includes(productId)) {
        // Cart includes item
        // Check if modifiers match
        let currentItemNumber = 0
        currentCart[type].forEach((currentItem) => {
          if ((JSON.stringify(currentItem.modifiers) === JSON.stringify(modifiers)) && currentItem.productId === productId) {
            // Should just increment amount for current item
            currentCart[type].splice(currentItemNumber, 1)
            cartPath.update(currentCart)
            loading.dismiss()
            this.toastCtrl.create({
              animated: true,
              message: currentItem.name + ' successfully removed. ',
              duration: 4000
            }).then(r => r.present())
          }
          currentItemNumber += 1
        })

      }


    })
  }


  public async getCart(userId: string) {
    return this.afStore.collection('users').doc(userId).collection('cart').doc('data').valueChanges()
  }

  public async clearCart(userId: string) {
    const cartPath = this.afStore.collection('users').doc(this.loggedInUid).collection('cart').doc('data')
    return cartPath.set({
      sfm: [],
      bistro: [],
      restaurant: []
    })
  }



}