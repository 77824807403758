import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { MenuService, Modifier } from 'src/app/services/menu.service';

@Component({
  selector: 'app-select-modifiers',
  templateUrl: './select-modifiers.component.html',
  styleUrls: ['./select-modifiers.component.scss'],
})
export class SelectModifiersComponent implements OnInit {
  productId: string
  type: string
  productName: string
  price: number
  productImage: string
  modifiers: Modifier[] = [{
    name: null,
    type: null,
    value: null,
    alternativeGroup: null,
    option: null,
    selectedSide: {
      allergens: null,
      area: null,
      calories: null,
      category: null,
      description: null,
      flags: null,
      id: null,
      identifiers: null,
      ingredients: null,
      media: null,
      name: 'null',
      modifiers: null,
    },
    sideNum: null,
    sideOptions: null
  }]

  constructor(
    private modalCtrl: ModalController,
    private menuService: MenuService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.getSides()
  }

  dismiss() {
    this.modalCtrl.dismiss()
  }

  getSides() {
    let i = 0
    let sideNum = 1
    this.modifiers.forEach((modifier) => {
      const pos = i
      i += 1
      if (modifier.type === 'side') {
        this.menuService.getFoodserviceSides(modifier.alternativeGroup).subscribe((res) => {
          const sideNumber = sideNum
          sideNum += 1
          this.modifiers[pos].sideOptions = res
          this.modifiers[pos].sideNum = sideNumber
          let resI = 0
          res.forEach((side) => {
            if(!side.modifiers) {
              res[resI].modifiers = []
            }
            resI += 1
            if(side.id === modifier.value) {
              this.modifiers[pos].selectedSide = side
            }
          })
        })
      }
    })
  }

  setSideValue(modifierPosition: number, value: string, sidePosition: number) {
    this.modifiers[modifierPosition].value = value
    this.modifiers[modifierPosition].selectedSide = this.modifiers[modifierPosition].sideOptions[sidePosition]
  }

  changeSideModifierValue(booleanState: boolean, modifierPosition: number, sideModifierPosition: number) {
    this.modifiers[modifierPosition].selectedSide.modifiers[sideModifierPosition].value = booleanState
  }

  changeOptionValue(booleanState: boolean, modifierPosition: number) {
    this.modifiers[modifierPosition].value = booleanState
  }

  addToCart() {
    // Construct modifiers text 
    let modifierText = []
    this.modifiers.forEach((modifier) => {
      switch(modifier.type) {
        case 'side':
          modifierText.push('Side: ' + modifier.selectedSide.name)
          modifier.selectedSide.modifiers.forEach((sideModifier) => {
            if(sideModifier.type=='option') {
              if(sideModifier.value===false) {
                modifierText.push('--- NO ' + sideModifier.option)
              } else {
                modifierText.push('--- with ' + sideModifier.option)
              }
            }
          })
          break;
        case 'option':
          if(modifier.value===false) {
            modifierText.push('NO ' + modifier.option)
          } else {
            // modifierText.push('- Add ' + modifier.option)
          }
          break;
      }
    })
    // Add item to cart
    this.dismiss()
    this.authService.addToCart(this.productId, this.type, this.productName, this.price, this.productImage, modifierText, true)
  }

}
