import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {
  stores: any[] = []
  user: any;
  emailList: string[]
  SearchResults: any[] = []
  searchQuery: string;
  resultName: string;
  loading: boolean = false;
  noResults: boolean = false;
  totalCount: number = 0
  currentCount: number = 0
  nextLink: string

  userSearch: string = '';
  userResults: any = [];
  request: any;
  requestState = 'none';
  userSearchDelay: any;
  callable: any;

  constructor(
    private functions: AngularFireFunctions,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }


  getSearch(event: any) {
    this.currentCount = 0
    this.stores = []
    this.noResults = false;
    this.searchQuery = event.detail.value
    if (this.searchQuery === '') {
      this.searchQuery = undefined
    }
    this.SearchResults = []
    if (this.searchQuery !== undefined) {
      console.log(this.searchQuery)

      this.loading = true
      const callable = this.functions.httpsCallable('searchLocation');

      const obs = callable({
        search: this.searchQuery
      });

      obs.subscribe(async (res) => {
        if (res.message) {
          this.loading = false
          console.log(res)
          this.stores = res.message.hits.hits
          this.currentCount = res.message.hits.total
          this.resultName = res.locationName
          this.totalCount = 0
          if (this.currentCount === 0) {
            this.noResults = true
          }
        } else if (res.error) {
          this.loading = false
          this.noResults = true
          console.log(res.error)
        }
      }, async (err) => {
        this.loading = false
        this.noResults = true
        console.log(err.message)
      });


    } else {
      console.log('message undefined')
    }
  }

  dismiss() {
    this.modalCtrl.dismiss()
  }

}
