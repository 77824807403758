import { NgModule } from '@angular/core';
import { DateFormatPipe } from 'src/app/pipes/date.pipe';
import { MenuItemPipe } from 'src/app/pipes/menu-item.pipe';
import { QuillModule } from 'ngx-quill';


@NgModule({
    imports: [
        QuillModule,
    ],
    declarations: [
        DateFormatPipe,
        MenuItemPipe
    ],
    exports: [
        DateFormatPipe,
        MenuItemPipe
    ]
})

export class SharedModule { }