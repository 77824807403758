import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface MenuItem {
  id: string;
  tags?: string;
  archived?: boolean;
  name: string;
  description: string;
  ingredients: string;
  location?: [string];
  calories: number;
  priority?: number;
  category: string;
  isBreathTakingItem?: boolean;
  isNew?: boolean;
  allergens: [string];
  flags: [string];
  media: {
    productImage: string;
    nutritionImage: string;
  }
  sideGroup?: [string];
  modifiers?: [Modifier];
  area?: [string];
  retail_price_cents?: number;
  identifiers?: {
    article: string;
    ocis: string;
    storepoint: string;
    supplier: string;
  }
}

export interface Picture {
  fileId: string,
  imgSmall: string,
  imgMedium: string,
  imgLarge: string
}

export interface Modifier {
  name: string;
  type: string;
  value: string | boolean;
  option?: string;
  alternativeGroup?: string;
  sideOptions?: MenuItem[];
  selectedSide?: MenuItem;
  sideNum?: number
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  public menuItemsCollection: AngularFirestoreCollection<MenuItem>;
  public archivedMenuCollection: AngularFirestoreCollection<MenuItem>;
  private sideCollection: AngularFirestoreCollection<MenuItem>;
  private menuItems: Observable<MenuItem[]>;
  private archivedMenuItems: Observable<MenuItem[]>;
  private sideItems: Observable<MenuItem[]>;



  constructor(private db: AngularFirestore) {
    this.menuItemsCollection = db.collection<MenuItem>('menu', ref => ref.where("archived", "==", false));
    this.archivedMenuCollection = this.db.collection<MenuItem>('menu', ref => ref.where("archived", "==", true));
    this.sideCollection = db.collection('menu', ref => ref.where("sideGroup", "array-contains", "default"))
    this.menuItems = this.menuItemsCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    this.archivedMenuItems = this.archivedMenuCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    this.sideItems = this.sideCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  getMenuItems() {
    return this.menuItems;
  }

  getArchivedMenuItems() {
    return this.archivedMenuItems;
  }

  getMenuItem(id) {
    return this.menuItemsCollection.doc<MenuItem>(id).valueChanges();
  }

  updateMenuItem(menuItem: MenuItem, id: string) {
    return this.menuItemsCollection.doc(id).update(menuItem);
  }

  addMenuItem(menuItem: MenuItem, id: string) {
    return this.menuItemsCollection.doc(id).set(menuItem);
  }

  deleteMenuItem(id) {
    return this.menuItemsCollection.doc(id).delete();
  }

  getSideItems() {
    return this.sideItems
  }

  getFoodserviceItemsByAreaCategory(location: string, category: string) {
    return this.db.collection<any>('menu', ref => ref.where("location", "array-contains", location).where("category", "==", category).where("published", "==", true).where("archived", "==", false).orderBy('priority', 'asc')).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  getFoodserviceSides(sideGroup: string) {
    return this.db.collection<any>('menu', ref => ref.where("sideGroup", "array-contains", sideGroup)).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }


}
