import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LoadingComponent } from '../components/loading/loading.component';



@NgModule({
  declarations: [LoadingComponent],
  imports: [
    IonicModule,
    CommonModule,
  ],
  exports: [LoadingComponent]
})
export class AllShareModule { }
