import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuService } from 'src/app/services/menu.service';
import { SfmService } from 'src/app/services/sfm.service';

@Pipe({
  name: 'menuItem'
})
export class MenuItemPipe implements PipeTransform {

  constructor(private menuService: MenuService, private sfmService: SfmService) {

  }

  async getData(value) {


  }


  transform(value: any, ...args: any[]): any {
    let name
    this.sfmService.getItem(value).subscribe(res => {
      if(!(res==undefined)){
        console.log(res.name)
        name = res.name;
      } else {
        this.menuService.getMenuItem(value).subscribe(res => {
          if(!(res==undefined)){
            console.log(res.name)
            name = res.name;
          }
        })
      }    
      return name || 'error';
  
    })
  }





}
