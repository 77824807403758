import { Injectable, CollectionChangeRecord } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface SFM {
  pa?: string
  id: string; 
  tags?: string;
  published?: boolean;
  article: string;
  businessStructure: {
    HomeFurnishingBusinessName: string;
    HomeFurnishingBusinessNo: string;
    ProductAreaName: string;
    ProductAreaNo: string;
  }
  identifiers: {
    article: string;
    ocis: string;
    storepoint: string;
    supplier: string;
  }
  localisedCommunications: [Communication]
  isBreathTakingItem: boolean;
  isNew: boolean;
  tempZone: string;
  name: string;
  retailPriceCents?: number;
  ingredients: string;
  description: string;
  location: [string];
  calories: number;
  servings?: number;
  category: [string];
  mobileOrderingType: [string];
  allergens: [string];
  flags: [string];
  media: {
    productImage: string;
    nutritionImage: string;
  }
  publishedKiosk: number;
  publishedMenu: number;
  publishedMobileOrdering: number;
  sitesEnabledKiosk: [number];
  sitesEnabledMenu: [number];
  sitesEnabledMobileOrdering: [number];
  sideGroup?: [string];
  complementaryItems?: [ComplementaryItems];
  availability: [Availability]
}


export interface ComplementaryItems {
  type: string;
  itemKey: {
    itemNo: string;
    itemType: string;
  }
}

export interface Availability {
  Country: string;
  PriceCents: number;
  Available: boolean;
}

export interface Communication {
  Benefits: [string];
  longBenefits: [LongBenefits];
  Measurements: [Measurements];
  LanguageCode: string;
  media: [Media];
}

export interface Media {
  name: string,
  typeName: string,
  altText: string
  variants: [ImageVarient, ImageVarient, ImageVarient,ImageVarient,ImageVarient]
}

export interface ImageVarient {
  href: string
}

export interface LongBenefits {
  Header: string;
  Name: string;
  Subject: string;
  text: string;
}

export interface Measurements {
  TextImperial: string;
  TextMetric: string;
  Type: string;
  TypeName: string;
}
@Injectable({
  providedIn: 'root'
})
export class SfmService {
  private sfmCollection: AngularFirestoreCollection<any>;
  private sfmItems: Observable<any[]>;

  constructor(private db: AngularFirestore) {
    this.sfmCollection = this.db.collection<any>('sfm');
    this.sfmItems = this.sfmCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }



  getMenuItems() {
    let sfmItems = this.sfmCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return sfmItems;
  }

  
  getMenuItemsByPa(paId: string) {
    return this.db.collection('sfm', ref => ref.where("pa", "==", paId).where("published", "==", true)).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data: any = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  getItem(id) {
    return this.sfmCollection.doc<SFM>(id).valueChanges();
  }

  setItem(item, id: string) {
    return this.sfmCollection.doc<SFM>(id).update(item);
  }

  deleteItem(id) {
    return this.sfmCollection.doc(id).delete();
  }
}
 