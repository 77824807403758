import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CustomerAuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private alertController: AlertController,
    private router: Router
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const uid = await this.auth.uid();
    const isLoggedIn = !!uid;
    try {
      const userInfo = await this.auth.getUser();
      console.log('coworker auth')
      console.log(userInfo)
      if (((userInfo.customer == false))) {
        console.log('logged in as co-worker')
        const alert = await this.alertController.create({
          header: 'Authentication Error',
          message: 'You can only place orders with a customer account.',
          buttons: ['Okay']
        });
        await alert.present();
        this.router.navigate(['/admin-login'], {
          queryParams: {
            redirectUrl: state.url
          }
        });
      }
    } catch {
      console.log('not logged in')
      this.router.navigate(['/login']);
    }

    return isLoggedIn;
  }


}
